/* c8 ignore start */
import { HeroUIProvider } from '@heroui/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { AllCommunityModule, ModuleRegistry } from 'ag-grid-community'
import { FlagsProvider } from 'flagged'
import { ThemeProvider } from 'next-themes'
import React, { useMemo } from 'react'
import type { LinksFunction, MetaFunction } from 'react-router'
import { data, Links, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from 'react-router'
import { StringProvider } from '~/contexts/stringContext'
import { genreService } from '~/services/GenreService.server'
import { programService } from '~/services/ProgramService.server'
import { stringService } from '~/services/StringService.server'
import userDataSet from '~/services/UserService.server'
import stylesheet from '~/tailwind.css?url'
import { Route } from './+types/root'
import { ComponentErrorBoundaryWrapper, RouteErrorBoundary } from './components/ErrorBoundary'
import { RouteTransition } from './components/RouteTransition'
import { ToastContainer } from './components/Toast'
import { SUPABASE_ANON_KEY, SUPABASE_URL, useSentry } from './config/config.server'
import { ServerContextProvider } from './contexts/serverContext'
import useStrings from './hooks/useStrings'
import { getCookieByName } from './lib/cookies'
import { drillTypeService } from './services/DrillTypeService.server'
import { tagService } from './services/TagService.server'
import { trackService } from './services/TrackService.server'

ModuleRegistry.registerModules([AllCommunityModule])

export const meta: MetaFunction = () => [
  {
    title: 'My Piano Trainer',
  },
]

export async function loader({ request }: Route.LoaderArgs) {
  const uds = await userDataSet(request)
  const theme = getCookieByName(request, 'theme') || 'light'
  await stringService.initialize()
  return data(
    {
      userData: uds.userData,
      genreTreeById: genreService.getGenreTreeById(),
      genreTreeOrdered: genreService.ordered(),
      programsById: programService.programs(),
      allTags: await tagService.allTags(),
      tracksById: await trackService.allTracks(),
      drillTypesById: await drillTypeService.allDrillTypes(),
      theme,
      translations: stringService.getTranslationMap(),
      useSentry,
      runEnv: process.env.RUN_ENV || 'mac',
      languages: stringService.getLanguages(),
      isTest: (process.env.DB_ENV || 'local') === 'test',
      supabaseUrl: SUPABASE_URL,
      supabaseAnonKey: SUPABASE_ANON_KEY,
    },
    {
      headers: {
        'Set-Cookie': await uds.cookieHeader(),
      },
    },
  )
}
interface DocumentProps {
  children: React.ReactNode
  title?: string
}
export function ErrorBoundary() {
  return <RouteErrorBoundary />
}
const queryClient = new QueryClient()
export const links: LinksFunction = () => [
  {
    rel: 'preload',
    href: stylesheet,
    as: 'style',
  },
  {
    rel: 'stylesheet',
    href: stylesheet,
    precedence: 'high',
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.googleapis.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preconnect',
    href: 'https://fonts.gstatic.com',
    crossOrigin: 'anonymous',
  },
  {
    rel: 'preload',
    href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap',
    as: 'style',
  },
  {
    rel: 'stylesheet',
    href: 'https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;400;500;700&family=Noto+Serif:wght@400;700&display=swap',
    precedence: 'high',
  },
]
export default function App() {
  const {
    userData,
    genreTreeById,
    genreTreeOrdered,
    programsById,
    allTags,
    tracksById,
    drillTypesById,
    theme,
    translations,
    useSentry,
    runEnv,
    languages,
    isTest,
    supabaseUrl,
    supabaseAnonKey,
  } = useLoaderData<typeof loader>()
  const { t } = useStrings()
  const Document = useMemo(() => {
    return ({ children, title }: DocumentProps) => (
      <html
        lang="en"
        data-theme={theme}
        data-ag-theme-mode={`${theme}-mpt`}
        style={{
          colorScheme: theme,
        }}
        className={theme === 'dark' ? 'dark-gradient-bg' : 'light-gradient-bg'}
      >
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width,initial-scale=1" />
          <meta name="color-scheme" content="light dark" />
          {title ? <title>{title}</title> : null}
          <Meta />
          <Links />
        </head>
        <body>
          <div id="app">{children}</div>
          <ScrollRestoration />
          <script
            dangerouslySetInnerHTML={{
              __html: `window.__ENV__ = ${JSON.stringify({
                useSentry,
                runEnv,
              })}`,
            }}
          />
          <Scripts />
        </body>
      </html>
    )
  }, [theme, useSentry, runEnv])
  return (
    <Document>
      <StringProvider translations={translations} languages={languages}>
        <HeroUIProvider>
          <ThemeProvider attribute="data-theme" defaultTheme="light">
            <QueryClientProvider client={queryClient}>
              <ServerContextProvider
                supabaseUrl={supabaseUrl}
                supabaseAnonKey={supabaseAnonKey}
                genreTreeById={genreTreeById}
                genreTreeOrdered={genreTreeOrdered}
                programsById={programsById}
                allTags={allTags}
                tracksById={tracksById}
                drillTypesById={drillTypesById}
                isTest={isTest}
              >
                <FlagsProvider
                  features={{
                    v1: runEnv !== 'prod',
                  }}
                >
                  <ToastContainer />
                  <ComponentErrorBoundaryWrapper message={t('An error occurred')}>
                    <RouteTransition>
                      <Outlet />
                    </RouteTransition>
                  </ComponentErrorBoundaryWrapper>
                </FlagsProvider>
              </ServerContextProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </HeroUIProvider>
      </StringProvider>
    </Document>
  )
}
/* c8 ignore end */
