/* Coverage:
 Statements: 8%
 Branches: 100%
 Functions: 0%
 (Generated by scripts/generate-coverage-comments.js - Do not edit this comment)
*/
import React from 'react'
import { useNavigation } from 'react-router'
import { LoadingSpinner } from './LoadingSpinner'
interface RouteTransitionProps {
  children: React.ReactNode
  /** Optional label for loading state */
  loadingLabel?: string
  /** Optional label for suspended content */
  suspenseLabel?: string
}
export function RouteTransition({
  children,
  loadingLabel = 'Loading page...',
  suspenseLabel = 'Loading content...',
}: RouteTransitionProps) {
  const DISABLE_TRANSITIONS = true
  const navigation = useNavigation()
  const isNavigating = navigation.state !== 'idle'
  if (DISABLE_TRANSITIONS) {
    return <>{children}</>
  }
  return (
    <section aria-busy={isNavigating}>
      {isNavigating && (
        <div aria-live="polite" role="status">
          <LoadingSpinner fullscreen ariaLabel={loadingLabel} />
        </div>
      )}
      <React.Suspense
        fallback={
          <div aria-live="polite" role="status">
            <LoadingSpinner fullscreen ariaLabel={suspenseLabel} />
          </div>
        }
      >
        {children}
      </React.Suspense>
    </section>
  )
}
